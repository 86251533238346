import React from 'react';
// import logo from './logo.svg';
import styles from './App.module.scss';
import TopSection from './Components/TopSection/TopSection';
import IntroSection from './Components/IntroSection/IntroSection';
import UXSection from './Components/UxSection/UXSection';
import CheckoutSection from './Components/CheckoutSection/CheckoutSection';
import BottomSection from './Components/BottomSection/BottomSection';
function App() {
  console.log(' Hey, I just met you and this is crazy,\n','But here\'s my number, so call me maybe\n','054-6840609\n', 'graphixbyroman@gmail.com');
  return (
    <div className={styles.app}>
      <TopSection></TopSection>
      <IntroSection></IntroSection>
      <UXSection></UXSection>
      <CheckoutSection></CheckoutSection>
      <BottomSection></BottomSection>
    </div>
  );
}

export default App;
