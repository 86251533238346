import React, { useState } from 'react'
import styles from './UxSection.module.scss';
import TextCard from '../TextCard/TextCard';
import Carousel from '../Carousel/Carousel';
import InfoDialog from '../InfoDialog/InfoDialog';
import useModal from '../../State/useModal';
import sectionContent from './UXSections.content';

export default function UXSection() {

    const { isShowing, openModal, closeModal } = useModal();
    const [content, setContent] = useState({});

    // const openDialog = () => {
    //     openModal();
    // }

    const closeDialog = () => {
        closeModal();
    }

    const handleCardClick = (cardIndex) => {
        setContent({ elements: sectionContent[cardIndex].content, img: `UXSection/Card-${cardIndex + 1}` });
        openModal();
    }

    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.sectionContainer}>
                <header className={styles.title}>UX/UI NETCRAFT ACADEMY PROJECT</header>
                <div className={styles.cardGrid}>
                    {sectionContent.map((card, cardIndex) =>
                       <div className={styles.cardWrapper}  key={`card-${cardIndex}`}> <TextCard {...card} onCLickCard={() => handleCardClick(cardIndex)} /></div>
                    )}
                </div>
                {/* <TextCard onCLickCard={openDialog} backgroundColor="#080326" color="white" title="E-COMMERCE WEBSITE" subTitle="FRIED BOLOGNE"></TextCard> */}
            </div>
            <InfoDialog isShowing={isShowing} onDialogCLose={closeDialog}><Carousel content={content}></Carousel></InfoDialog>
        </div >
    )
}
