import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import styles from './TextCard.module.scss'
export default class TextCard extends Component {
   
    static propTypes = {
        title: PropTypes.string,
        subTitle: PropTypes.string,
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
        onCLickCard: PropTypes.func
    }
    static defaultProps = {
        backgroundColor: 'white' ,
        color:'black' 
    }
    render() {
        const {backgroundColor, title, subTitle, color, onCLickCard } = this.props
        return <Card >
            <div onClick={onCLickCard} style={{backgroundColor}} className={styles.textcardwrapper}>
                <header style={{color}} className={styles.title}>{title}</header>
                <div style={{color}} className={styles.subTitle}>{subTitle}</div>
            </div>
        </Card>
    }

}
