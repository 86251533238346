import React, { useState } from 'react'
import styles from './BottomSection.module.scss';
// import TextCard from '../TextCard/TextCard';
import Carousel from '../Carousel/Carousel';
import InfoDialog from '../InfoDialog/InfoDialog';
import useModal from '../../State/useModal';
import sectionContent from './BottomSection.content';
import MediaCard from '../MediaCard/MediaCard';

export default function BottomSection() {

    const { isShowing, openModal, closeModal } = useModal();
    const [startIndex, setStartIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { img, elements } = sectionContent;

    const closeDialog = () => {
        closeModal();
    }

    const handleCardClick = (elementIndex) => {
        setStartIndex(elementIndex);
        setSelectedIndex(elementIndex)
        openModal();
    }

    const handleIndexChange = (targetIndex) => {
        setSelectedIndex(targetIndex);
    }

    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.cardGrid}>
                {elements.map((element, elementIndex) => {
                    const bgImage = require(`../Assets/imgs/${img}/Content/${elementIndex + 1}.png`)
                    return <div className={styles.cardWrapper} key={`card-${elementIndex}`}><MediaCard backgroundImage={bgImage} bgColor={element.bgColor} onCLickCard={() => handleCardClick(elementIndex)} /></div>
                })}
            </div>
            <InfoDialog isShowing={isShowing} onDialogCLose={closeDialog} bgColor={elements[selectedIndex].bgColor} controlsColor={elements[selectedIndex].controlsColor}><Carousel content={sectionContent} startIndex={startIndex} onSelectIndex={handleIndexChange}></Carousel></InfoDialog>
            <div className={styles.note}>
                Some of the designs were made in Gofman Creative studio
            </div>
        </div >
    )
}
