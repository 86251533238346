import React from 'react'
import styles from './TopSection.module.scss';
import InfoDialog from '../InfoDialog/InfoDialog';
import Slide from '@material-ui/core/Slide';

import useModal from '../../State/useModal';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function TopSection() {


    const { isShowing, openModal, closeModal } = useModal();


    const openDialog = () => {
        openModal();
       }

    const closeDialog = () => {
        closeModal();
    }

    return <div className={styles.topSection}>
        <div className={styles.topSectionWrpper}>
            <nav className={styles.navBar}>
                <section className={styles.left}>
                    <span className="name">ROMAN FEIGIN</span>
                </section>
                <section className={styles.right}>
                    <a href="https://firebasestorage.googleapis.com/v0/b/roman-feigin-web-78e9c.appspot.com/o/roman-feigin-cv.pdf?alt=media&token=0b14676d-2272-4584-8cde-d57e0d7865a5" className={styles.link} download target="_blank" rel="noopener noreferrer" >
                        <svg className={styles.downloadSVG} viewBox="38.25 0 19.684 23.902">
                            <path fill="rgba(255,255,255,1)" id="Path_67" d="M 57.93428039550781 8.436120986938477 L 52.31020355224609 8.436120986938477 L 52.31020355224609 0 L 43.87407684326172 0 L 43.87407684326172 8.436120986938477 L 38.25 8.436120986938477 L 48.09214019775391 18.27826118469238 L 57.93428039550781 8.436120986938477 Z M 38.25 21.09030342102051 L 38.25 23.90234375 L 57.93428039550781 23.90234375 L 57.93428039550781 21.09030342102051 L 38.25 21.09030342102051 Z">
                            </path>
                        </svg> DOWNLOAD CV
                    </a><span className={styles.letsTalk} onClick={openDialog}> LET'S TALK</span>
                </section>
            </nav>
            <div className={styles.mainTitleWrapper}>
                <span className={styles.mainTitle}>D<span className={styles.iLetter}>i</span>SCOVER</span>
            </div>
            <div className={styles.arrowDown}>
      <svg version="1.1" id="Capa_1" x="0px" y="0px"
	 viewBox="0 0 185.344 185.344" style={{enableBackground: "new 0 0 185.344 185.344"}} >
   <g>
	<g>
		<path fill="white" d="M92.672,144.373c-2.752,0-5.493-1.044-7.593-3.138L3.145,59.301c-4.194-4.199-4.194-10.992,0-15.18
			c4.194-4.199,10.987-4.199,15.18,0l74.347,74.341l74.347-74.341c4.194-4.199,10.987-4.199,15.18,0
			c4.194,4.194,4.194,10.981,0,15.18l-81.939,81.934C98.166,143.329,95.419,144.373,92.672,144.373z"/>
	</g>
</g>
</svg>

            </div>
        </div>
        <InfoDialog isShowing={isShowing} onDialogCLose={closeDialog} Transition={Transition}>
            <div className={styles.infoContent}>
                <div className={styles.intro}>
                    <span>Hey, I just met you and this is crazy,</span>
                    <br />
                    <span>But here's my number, so call me maybe</span>
                </div>
                <div className={styles.phone}>054-6840609</div>
                <div className={styles.email}>graphixbyroman@gmail.com</div>
            </div>
        </InfoDialog>
    </div>
}