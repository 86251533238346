import { CARD_TYPES } from '../../constants';
export default [
    [
        {
            cardType: CARD_TYPES.text_card,
            backgroundColor: '#080326',
            subTitle: 'WINTER PARTY',
            title: 'TIV-TAM',
            color: 'white',
            content: [
                {
                    title:'WINTER PARTY',
                    subTitle:'TIV TAM',
                    description:'Save the date for Tiv Tam\'s employees. The concept was Winter Party.',
                    color: '#EDAB06'
                },
                {
                    title:'WINTER PARTY',
                    subTitle:'TIV TAM',
                    description:'Proceeding with the winter theme to the "Welcome Wall" with a big and gorgeous picture of a forest in snow.',
                    color: '#EDAB06'
                },
                {
                    title:'WINTER PARTY',
                    subTitle:'TIV TAM',
                    description:'The menu, less winter-ish, though the same color palette stayed and a bit snowflakes to remind people it\'s still a winter party.',
                    color: '#EDAB06'
                },
            ],
        },
        {
            cardType: CARD_TYPES.text_card,
            backgroundColor: '#EDAB06',
            subTitle: 'BIRTHDAY PARTY',
            title: 'YULI\'S ICE CREAM',
            color: 'black',
            content: [
                {
                    title:'BIRTHDAY PARTY',
                    subTitle:'YULI\'S ICE CREAM',
                    description:'An ice cream birthday party. <br/>colorful pastel colors with white background so it won\'t be too much.',
                    color: '#EDAB06'
                },
                {
                    title:'BIRTHDAY PARTY',
                    subTitle:'YULI\'S ICE CREAM',
                    description:'More of that birthday party designs,<br/> big sticker to stick on a counter and flags to hang all over the place.<br/> Proceeding with the pastel colors.',
                    color: '#EDAB06'
                },
            ],
        },
        {
            cardType: CARD_TYPES.text_card,
            backgroundColor: '#D61A98 ',
            subTitle: 'SPORT WATCH CAMPAIGN',
            title: 'POLAR',
            color: 'white',
            content: [
                {
                    title:'SPORT WATCH CAMPAIGN',
                    subTitle:'POLAR',
                    description:'A landing page for new sport watch series.',
                    color: '#EDAB06'
                },
                {
                    title:'SPORT WATCH CAMPAIGN',
                    subTitle:'POLAR',
                    description:'Proceeding the sport watch campaign, banners for websites.',
                    color: '#EDAB06'
                },
                {
                    title:'SPORT WATCH CAMPAIGN',
                    subTitle:'POLAR',
                    description:'More of the sport watch campaign, banner for websites.',
                    color: '#EDAB06'
                },
            ],
        }


    ],
    [
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'MAILER', 
            title:'ZARA', 
            content: [
                {
                    title:'MAILER',
                    subTitle:'ZARA',
                    description:'Purim party for Zara. <br/>The main idea was to create Save The Date that will apply towards young people and feel more like party than an event.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'MAILER', 
            title:'TIV TAM', 
            content: [
                {
                    title:'MAILER',
                    subTitle:'TIV TAM',
                    description:'Save the date for Tiv Tam\'s employees.<br/> The concept was market, I delivered it through picture colors and items that are sold in a real open market.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'MAILER', 
            title:'BEZEQ INTERNATIONAL', 
            content: [
                {
                    title:'MAILER',
                    subTitle:'BEZEQ INTERNATIONAL',
                    description:'A collabe mailer between Bezeq International and Microsoft in a summer-ish style.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'MAILER', 
            title:'UPS', 
            content: [
                {
                    title:'MAILER',
                    subTitle:'UPS',
                    description:'A Save The Date for the UPS<br/> business customers who will <br/> fly for event in Germany.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'MAILER', 
            title:'YELLOW', 
            content: [
                {
                    title:'MAILER',
                    subTitle:'VISHAY',
                    description:'Yellow store released an app and wanted <br/>to celebrate it by making a competition <br/>for the employees, with big winning prizes.',
                    color: '#EDAB06'
                },
                {
                    title:'MAILER',
                    subTitle:'YELLOW',
                    description:'Another mailer for the Yellow store app.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'MAILER', 
            title:'SHELA', 
            content: [
                {
                    title:'MAILER',
                    subTitle:'SHELA',
                    description:'Save The Date for Shela.<br/> I think it\'s really cool and eye pleasing to see a colorful event and this is what I did.<br/> Starting with Save The Date and moving on to where the event is planned to be.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'MAILER', 
            title:'NADINE BOMMER', 
            content: [
                {
                    title:'MAILER',
                    subTitle:'NADINE BOMMER',
                    description:'An informative mailer for parents whose kids are learning in Nadine Bommer\'s dance school.',
                    color: '#EDAB06'
                },
                {
                    title:'MAILER',
                    subTitle:'NADINE BOMMER',
                    description:'Colorful Purim mailer for the parent.',
                    color: '#EDAB06'
                }
            ],
        },
    ],
    [
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'SOCIAL MEDIA', 
            title:'ORANGETHEORY FITNESS', 
            content: [
                {
                    title:'SOCIAL MEDIA',
                    subTitle:'ORANGETHEORY FITNESS',
                    description:'Facebook post using an old Chinese smart sentence... Not really',
                    color: '#EDAB06'
                },
                {
                    title:'SOCIAL MEDIA',
                    subTitle:'ORANGETHEORY FITNESS',
                    description:'Story format for the same<br/> not old Chinese smart sentence.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'SOCIAL MEDIA', 
            title:'INDEPENDENCE DAY', 
            content: [
                {
                    title:'SOCIAL MEDIA',
                    subTitle:'INDEPENDENCE DAY',
                    description:'Facebook post I\'ve made long ago<br/> for the Israel\'s 69th independence day.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'SOCIAL MEDIA', 
            title:'MEMORIAL DAY', 
            content: [
                {
                    title:'SOCIAL MEDIA',
                    subTitle:'MEMORIAL DAY',
                    description:'Facebook post I\'ve made long ago for the Israel\'s soldiers memorial day.',
                    color: '#EDAB06'
                }
            ],
        },
    ],
    [
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'T-SHIRT', 
            title:'ESET', 
            bgColor: 'white',
            bgSize: '80%',
            content: [
                {
                    title:'T-SHIRT',
                    subTitle:'ESET',
                    description:'A key-visual on a shirt for the <br/>  working staff in ESET.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'T-SHIRT', 
            title:'NADINE BOMMER', 
            bgColor: 'white',
            bgSize: '80%',
            content: [
                {
                    title:'T-SHIRT',
                    subTitle:'NADINE BOMMER',
                    description:'A shirt for dancers in a dance competition.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'T-SHIRT', 
            title:'BIKERS EVENT', 
            bgColor: 'white',
            bgSize: '80%',
            content: [
                {
                    title:'T-SHIRT',
                    subTitle:'BIKERS EVENT',
                    description:'Key-visual for a bikers event.',
                    color: '#EDAB06'
                }
            ],
        },
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'T-SHIRT', 
            title:'FIRE DEPARTMENT', 
            bgColor: 'white',
            bgSize: '80%',
            content: [
                {
                    title:'T-SHIRT',
                    subTitle:'FIRE DEPARTMENT',
                    description:'A friend who lives in Nahal Oz, asked me <br/> if I can make theirs new fire fighting team <br/> a symbol.',
                    color: '#EDAB06'
                }
            ],
        }
    ],
    [
        {
            cardType: CARD_TYPES.media_card,
            subTitle: 'WEDDING INVITE', 
            title:'ILLUSTRATION', 
            bgSize: '80%',
            bgColor: '#CAE9E4',
            content: [
                {
                    title:'WEDDING INVITE',
                    subTitle:'ILLUSTRATION',
                    description:'A Save The Date for friend\'s wedding, the figures were made by me.',
                    color: '#EDAB06'
                },
            ],
        }
    ]
] 