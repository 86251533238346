import { CARD_TYPES } from '../../constants';

export default   [
    {
        cardType: CARD_TYPES.text_card,
        backgroundColor: '#080326',
        subTitle: 'FRIED BOLOGNE',
        title: 'E-COMMERCE WEBSITE',
        color: 'white',
        content: [
            {
                title:'FRIED BOLOGNE',
                subTitle:'E-COMMERCE WEBSITE',
                description:'Home page of an e-commerce website.',
                color: '#EDAB06'
            },
            {
                title:'FRIED BOLOGNE',
                subTitle:'E-COMMERCE WEBSITE',
                description:'Mega menu can shine on an e-commerce site, because it can fit much more links in it and gives you the option to organize the info in a more readable way.',
                color: '#EDAB06'
            },
            {
                title:'FRIED BOLOGNE',
                subTitle:'E-COMMERCE WEBSITE',
                description:'A product page with all <br/> kind of filter options.',
                color: '#EDAB06'
            },
            {
                title:'FRIED BOLOGNE',
                subTitle:'E-COMMERCE WEBSITE',
                description:'Product page with all the valuable information for the user. Organized in hierarchy of what is much more valuable for the user.',
                color: '#EDAB06'
            }
        ],
    },
    {
        cardType: CARD_TYPES.text_card,
        backgroundColor: '#D61A98',
        subTitle: 'YOGA TEACHER',
        title: 'LANDING PAGE',
        color: 'white',
        content: [
            {
                title:'YOGA TEACHER',
                subTitle:'LANDING PAGE',
                description:'A wireframe for a landing page <br/> of a yoga teacher.',
                color: '#EDAB06'
            },
            {
                title:'YOGA TEACHER',
                subTitle:'LANDING PAGE',
                description:'The design of the landing page <br/>  for the yoga teacher.<br/> I used a picture that shows in door class, because the lessons are in a studio. <br/>But also I wanted to show hard position, something that catches cool for Instagram, so you can show off your yoga moves. <p/> The colors I used is pastel peach and black, they are relaxing colors, like yoga.',
                color: '#EDAB06'
            }
        ],
    },
]