export default {
    img: 'BottomSection',
    elements:[
        {
            title:'LOGO DESIGN',
            subTitle:'OUR HEART TO JERUSALEM',
            description:'This logo was made for a Jerusalem <br/>Chinese tour guide company.',
            color: '#EDAB06',
            bgColor:'#080326',
            textColor: 'white',
            controlsColor: 'white'
        },
        {
            title:'LOGO DESIGN',
            subTitle:'BELLWOOD BAR',
            description:'Logo remake for a bar in Jerusalem, unfortunately it went bank rapt before <br/>the logo was done.<br/> I\'ve changed the name of the bar on a logo.',
            color: '#EDAB06',
            bgColor:'#D61A98',
            textColor: 'white',
            controlsColor: 'white'
        },
        {
            title:'LOGO DESIGN',
            subTitle:'MEITAR',
            description:'A logo for music event <br/>productions company.<br/> The idea to sum many instruments <br/>under one dome.',
            color: '#EDAB06',
            bgColor:'#16FFBD',
            textColor: 'black',
            controlsColor: 'black'
        },
        {
            title:'LOGO DESIGN',
            subTitle:'AXIOS',
            description:'A logo for a company that helps people <br/>in need, to manage better their own money.',
            color: '#EDAB06',
            bgColor:'#080326',
            textColor: 'white',
            controlsColor: 'white'
        },
        {
            title:'LOGO DESIGN',
            subTitle:'MEITAR',
            description:'One of the logo sketches I did, <br/>this one I liked and saved for <br/> my personal use.',
            color: '#EDAB06',
            bgColor:'#16FFBD',
            textColor: 'black',
            controlsColor: 'black'
        },
        {
            title:'LOGO DESIGN',
            subTitle:'ALEXANDRA THE VIOLINIST',
            description:'A logo for a violinist.<br/> My opinion music is an art and all artist have they\'re signature.<br/> So why not make this violinist signature <br/>as a logo, although this is a font and not her real signature, it is definitely looks like.',
            color: 'white',
            bgColor:'#EDAB06',
            textColor: 'black',
            controlsColor: 'black'
        },
        {
            title:'LOGO DESIGN',
            subTitle:'DOOMSDAY APP',
            description:'A logo I\'ve made long time ago. <br/>I was looking for inspiration and have found this style called "Atomic Age". <br/>It\'s cool retro style in 60s if I\'m correct, <br/>so I did logo for an app in this style.',
            color: '#EDAB06',
            bgColor:'#16FFBD',
            textColor: 'black',
            controlsColor: 'black'
        },
        {
            title:'LOGO DESIGN',
            subTitle:'MAINSTREAM BAR & KITCHEN',
            description:'A logo for a bar in Jerusalem.<br/> When I came to the bar to meet<br/> the owner, I saw big bulbs above<br/> the bar counter and from there<br/> came the idea for this logo.',
            color: 'white',
            bgColor:'#EDAB06',
            textColor: 'black',
            controlsColor: 'black'
        },
        {
            title:'LOGO DESIGN',
            subTitle:'NADINE BOOMER',
            description:'It\'s a stamp logo for a dancing company that celebrates its 30th anniversary.',
            color: '#EDAB06',
            bgColor:'#16FFBD',
            textColor: 'black',
            controlsColor: 'black'
        }
    ]
} 