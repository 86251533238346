import React, { useState } from 'react'
import styles from './CheckoutSection.module.scss';
import sectionContent from './CheckoutSection.content'
import { CARD_TYPES } from '../../constants';
import TextCard from '../TextCard/TextCard';
import Carousel from '../Carousel/Carousel';
import InfoDialog from '../InfoDialog/InfoDialog';
import MediaCard from '../MediaCard/MediaCard';
import useModal from '../../State/useModal'

export default function CheckoutSection() {

    const { isShowing, openModal, closeModal } = useModal();
    const [content, setContent] = useState({});


    const closeDialog = () => {
        closeModal();
    }

    const handleCardClick = (sectionindex, cardIndex) => {
        setContent({ elements: sectionContent[sectionindex][cardIndex].content, img: `CheckOutSection/Section-${sectionindex + 1}/Card-${cardIndex + 1}` });
        openModal();
    }

    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.sectionBackground}>
                <div className={styles.top}></div>
                <div className={styles.bottom}>
                    <div className={styles.rocket}></div>
                </div>
            </div>
            <header className={styles.topTitle}>CHECK ME OUT</header>
            {
                sectionContent.map((section, sectionIndex) => {
                    return <div className={styles.cardGrid} key={`card-section${sectionIndex}`}>
                        {section.map((card, cardIndex) => {
                            switch (card.cardType) {
                                case CARD_TYPES.text_card:
                                    return <div className={styles.cardWrapper} key={`card-${sectionIndex}-${cardIndex}`}><TextCard {...card} onCLickCard={() => handleCardClick(sectionIndex, cardIndex)} /></div>
                                case CARD_TYPES.media_card:
                                    const bgImage = require(`../Assets/imgs/CheckOutSection/Section-${sectionIndex + 1}/Card-${cardIndex + 1}/${cardIndex + 1}.png`);
                                    return <div className={styles.cardWrapper} key={`card-${sectionIndex}-${cardIndex}`}><MediaCard {...card} backgroundImage={bgImage} onCLickCard={() => handleCardClick(sectionIndex, cardIndex)} /></div>
                                default:
                                    return null;
                            }
                        }
                        )}
                    </div>
                })
            }
            <InfoDialog isShowing={isShowing} onDialogCLose={closeDialog}><Carousel content={content}></Carousel></InfoDialog>
        </div >
    )
}

