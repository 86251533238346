import React from 'react'
import PropTypes from 'prop-types';
import styles from './Divider.module.scss';

export default function Divider(props){
    const {color, width} = props;
    return <div className={styles.divider} style={{ borderColor: color, width }}></div>
};

Divider.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
}

Divider.defaultProps= {
    width: '130px'
}