import React, { useState } from 'react'
import PropTypes from 'prop-types';
import styles from './Carousel.module.scss';
import Divider from '../Divider/Divider';
import Fade from '@material-ui/core/Fade';


export default function Carousel(props) {

    const [index, setIndex] = useState(props.startIndex);
    const { content, onSelectIndex } = props;
    const { elements, img } = content;
    const { title, subTitle, color, description, controlsColor, textColor } = elements[index];

    const handleNextContentElement = () => {
        const { content: { elements } } = props;
        if(index < elements.length - 1){
            const newIndex = index + 1
            setIndex(newIndex);
            onSelectIndex && onSelectIndex(newIndex);
        } 
    }

    const handlePrevContentElement = () => {
        index > 0 && setIndex(index - 1);
        if(index > 0){
            const newIndex = index - 1
            setIndex(newIndex)
            onSelectIndex && onSelectIndex(newIndex);
        } 
    }

    const currImage = require(`../Assets/imgs/${img}/Content/${index + 1}.png`);

    const isLast = index + 1 === elements.length;
    const isFirst = index === 0;
    return (
        <div className={styles.carouselWrapper}>
            <div className={styles.carouselContainer}>
                <div className={styles.left}>
                    <Fade in timeout={1000}>
                        <span className={styles.subTitle} style={ textColor && {color:textColor}}>{subTitle}</span>
                    </Fade>
                    <Fade in timeout={1000}>
                        <header className={styles.title}  style={ textColor && {color:textColor}}>{title}</header>
                    </Fade>
                    <Divider color={color}></Divider>
                    <Fade in timeout={1000}>
                        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }}  style={ textColor && {color:textColor}}></div>
                    </Fade>
                    {elements.length > 1 && <div className={styles.controls}>
                        <span className={styles.prev} onClick={handlePrevContentElement}><svg className={[(isFirst ? styles.first : ''), styles.leftChevron].join(' ')} viewBox="226.347 -0.003 17.428 31.239">
                            <path fill={!isFirst ? (controlsColor || 'rgba(8, 3, 38, 1)s') : undefined} d="M 228.1566009521484 31.23551177978516 C 227.6945953369141 31.23551177978516 227.2307891845703 31.05954933166504 226.8768768310547 30.70476150512695 C 226.1699676513672 29.99790573120117 226.1699676513672 28.85397720336914 226.8768768310547 28.14711761474609 L 239.4084320068359 15.61639022827148 L 226.8768768310547 3.084813833236694 C 226.1699676513672 2.377952337265015 226.1699676513672 1.233021974563599 226.8768768310547 0.5271679759025574 C 227.5837249755859 -0.1797232925891876 228.7286529541016 -0.1797232925891876 229.4353485107422 0.5271679759025574 L 243.2448272705078 14.33662223815918 C 243.9517059326172 15.04351234436035 243.9517059326172 16.18841171264648 243.2448272705078 16.89426612854004 L 229.4353485107422 30.70461082458496 C 229.0825958251953 31.05854034423828 228.6195831298828 31.23551177978516 228.1566009521484 31.23551177978516 Z">
                            </path>
                        </svg></span>
                        <span className={styles.next} onClick={handleNextContentElement}>
                            <svg className={[(isLast ? styles.last : ''), styles.rightChevron].join(' ')} viewBox="226.347 -0.003 17.428 31.239">
                                <path fill={!isLast ? (controlsColor || 'rgba(8, 3, 38, 1)s') : undefined} d="M 228.1566009521484 31.23551177978516 C 227.6945953369141 31.23551177978516 227.2307891845703 31.05954933166504 226.8768768310547 30.70476150512695 C 226.1699676513672 29.99790573120117 226.1699676513672 28.85397720336914 226.8768768310547 28.14711761474609 L 239.4084320068359 15.61639022827148 L 226.8768768310547 3.084813833236694 C 226.1699676513672 2.377952337265015 226.1699676513672 1.233021974563599 226.8768768310547 0.5271679759025574 C 227.5837249755859 -0.1797232925891876 228.7286529541016 -0.1797232925891876 229.4353485107422 0.5271679759025574 L 243.2448272705078 14.33662223815918 C 243.9517059326172 15.04351234436035 243.9517059326172 16.18841171264648 243.2448272705078 16.89426612854004 L 229.4353485107422 30.70461082458496 C 229.0825958251953 31.05854034423828 228.6195831298828 31.23551177978516 228.1566009521484 31.23551177978516 Z">
                                </path>
                            </svg>
                        </span>
                    </div>}
                </div>
                <div className={styles.right}>
                    <Fade in timeout={1000}>
                        <div className={styles.img} style={{ backgroundImage: `url(${currImage})` }}></div>
                    </Fade>
                </div>
            </div>
        </div>

    )

}

Carousel.propTypes = {
    content: PropTypes.shape({
        img: PropTypes.string,
        elements: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            subTitle: PropTypes.string,
            description: PropTypes.string,
            img: PropTypes.string,
            color: PropTypes.string,
            controlsColor: PropTypes.string,
            textColor: PropTypes.string
        }),
        )
    }),
    startIndex: PropTypes.number,
    onSelectIndex: PropTypes.func
}

Carousel.defaultProps  = {
    startIndex: 0
}

