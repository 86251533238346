import React, { Component } from 'react'
import styles from './IntroSection.module.scss';
export default class IntroSection extends Component {
    render() {
        return (
            <div className={styles.introSectionWrapper}>
                <div className={styles.sectionsWrapper}>
                    <section className={styles.leftSection}>
                        <header>YOUR FRIENDLY NEIGHBORHOOD</header>
                        <article>
                            <p>
                                "Why is a word discover written up there"? <br/> If this is what you're asking yourself... or me... <br/> Well, because discover new things is enriching, adding to this, as a designers <br/> we must always learn new things, different approaches and be updated <br/>and that is why the word discover written up there.
                            </p>
                            <p>
                                So... after a bit prologue, my name's Roman and I'm a graphic designer. <br/>Moving forward is a must for me. <br/> I'm going to course of UI/UX in NetCraft Academy right now because of that reason, <br/>to learn more, to move forward. 
                            </p>
                            <p>
                                BTW. <br/>I love colors!
                            </p>
                        </article>
                    </section>
                    <section className={styles.rightSection}>
                        <div className={styles.bluePlanet}></div>
                        <div className={styles.yellowPlanet}></div>
                    </section>
                </div>
            </div >
        )
    }
}
