import React from 'react'
import PropTypes from 'prop-types';
import styles from './InfoDialog.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: '1312px',
        height: '879px',
        width: '1312px',
        margin: '0px'
    },
}));

export default function InfoDialog(props) {

    const classes = useStyles();
    const { isShowing, onDialogCLose, Transition, bgColor, controlsColor } = props;


    return (
        <Dialog open={isShowing} onClose={onDialogCLose} classes={{ paper: classes.dialogPaper }} TransitionComponent={Transition}>
            <div className={styles.dialogContainer} style={{ ...(bgColor ? { backgroundColor: bgColor } : {}) }}>
                <div className={styles.dialogTop}>	<svg onClick={onDialogCLose} viewBox="0 0.021 23.364 23.363" className={styles.closeIcon}>
                    <path fill={controlsColor ? controlsColor : 'rgb(8, 3, 38)'} d="M 13.74865627288818 11.70337677001953 L 22.93636894226074 2.515666007995605 C 23.50717353820801 1.945341229438782 23.50717353820801 1.019944071769714 22.93636894226074 0.4496195912361145 C 22.36554336547852 -0.1212070062756538 21.44112396240234 -0.1212070062756538 20.87030029296875 0.4496195912361145 L 11.6826114654541 9.637307167053223 L 2.49442195892334 0.4496195912361145 C 1.923595070838928 -0.1212070062756538 0.9991791844367981 -0.1212070062756538 0.4283526539802551 0.4496195912361145 C -0.1424510627985001 1.019944071769714 -0.1424510627985001 1.945341229438782 0.4283526539802551 2.515666007995605 L 9.616541862487793 11.70337677001953 L 0.4283526539802551 20.89106559753418 C -0.1424510627985001 21.46139144897461 -0.1424510627985001 22.38678741455078 0.4283526539802551 22.95713424682617 C 0.7137659788131714 23.24204635620117 1.087821841239929 23.384765625 1.461398601531982 23.384765625 C 1.834952592849731 23.384765625 2.20900821685791 23.24204635620117 2.49442195892334 22.95663452148438 L 11.6826114654541 13.76894283294678 L 20.87030029296875 22.95663452148438 C 21.15571403503418 23.24204635620117 21.5297679901123 23.384765625 21.90332412719727 23.384765625 C 22.27690124511719 23.384765625 22.65095520019531 23.24204635620117 22.93636894226074 22.95663452148438 C 23.50717353820801 22.38630867004395 23.50717353820801 21.46091270446777 22.93636894226074 20.89058876037598 L 13.74865627288818 11.70337677001953 Z">
                    </path>
                </svg></div>
                <div className={styles.dialogContent}>
                    {props.children}

                </div>
            </div>
        </Dialog>)

}

InfoDialog.propTypes = {
    isShowing: PropTypes.bool.isRequired,
    onDialogCLose: PropTypes.func.isRequired,
    Transition: PropTypes.object,
    bgColor: PropTypes.string,
    controlsColor: PropTypes.string
}
