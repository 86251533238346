import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import styles from './MediaCard.module.scss'
export default class MediaCard extends Component {

    static propTypes = {
        title: PropTypes.string,
        subTitle: PropTypes.string,
        backgroundImage: PropTypes.string,
        onCLickCard: PropTypes.func,
        bgColor: PropTypes.string,
        bgSize: PropTypes.string
    }

    render() {
        const { backgroundImage, title, subTitle, onCLickCard, bgColor, bgSize } = this.props;
        return <Card >
            <div onClick={onCLickCard} className={[styles.mediaCardwrapper, (!title && !subTitle) ? styles.fullImg : ''].join(' ')} style={{
                ...(bgColor ? { backgroundColor: bgColor } : {})
            }}>
                <div className={styles.img} style={{ backgroundImage: `url(${backgroundImage})`, ...(bgSize ? { backgroundSize: bgSize } : {}) }}></div>
                {(title || subTitle) &&
                    <div className={styles.content}>
                        <header className={styles.title}>{title}</header>
                        <div className={styles.subTitle}>{subTitle}</div>
                    </div>
                }
            </div>
        </Card>
    }

}
